/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';
import BaseLayout from "../components/layout/BaseLayout";
import logo from "../assets/images/logo.svg";

const PrivacyPolicyPage = () => {
    return (
        <BaseLayout>
            <div className="min-h-screen flex flex-col justify-center items-center">
                <div className="container mx-auto py-4 px-4">
                    {/* Header */}
                    <header className="mb-6 text-center">
                        <h1 className="text-3xl font-bold text-black">개인정보 처리방침</h1>
                        <p className="text-black mt-2">포토 키오스크 서비스의 개인정보 처리방침</p>
                    </header>

                    {/* Content Section */}
                    <section className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                            1. 개인정보의 수집 및 이용 목적
                        </h2>
                        <p className="text-gray-700 leading-relaxed mb-6">
                            포토 키오스크 서비스는 고객님이 업로드한 사진 및 동영상을 출력 및 QR코드를 통한 다운로드 제공 목적으로 수집합니다.
                            원칙적으로 고객의 데이터를 다른 용도로 사용하거나 제3자에게 제공하지 않습니다.
                            단, 3조항에 따라 처리될 수 있습니다.
                        </p>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">2. 개인정보의 보관 및 파기</h2>
                        <ul className="list-disc list-inside text-gray-700 leading-relaxed mb-6">
                            <li>
                                업로드된 사진 및 동영상은 <span className="font-bold">24시간</span> 동안 보관됩니다.
                            </li>
                            <li>24시간 경과 후 자동으로 삭제되며, 복구가 불가능합니다.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">3. 개인정보 제3자 제공</h2>
                        <p className="text-gray-700 leading-relaxed mb-6">
                            고객의 사진 및 동영상은 원칙적으로 제3자에게 제공되지 않습니다. 단, 다음의 경우에 한해
                            제공될 수 있습니다:
                        </p>
                        <ul className="list-disc list-inside text-gray-700 leading-relaxed mb-6">
                            <li>
                                **법적 요청**: 법적 의무를 준수하기 위해 관련 기관(예: 법원, 수사 기관)의 요청이 있는
                                경우.
                            </li>
                            <li>
                                **서비스 운영 필요**: 서비스 제공을 위해 외부 업체(예: 클라우드 서비스 제공자, 데이터 처리
                                위탁 업체, 소프트웨어 개발사)에 공유해야 할 경우.
                            </li>
                        </ul>
                        <p className="text-gray-700 leading-relaxed mb-6">
                            고객의 개인정보는 제공 시에도 목적 외 사용이 금지되며, 필요한 경우에 한해 최소한의 정보만
                            제공합니다.
                        </p>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">4. 고객의 권리</h2>
                        <p className="text-gray-700 leading-relaxed mb-6">
                            고객님은 업로드한 사진 및 동영상의 삭제를 요청할 수 있습니다. 단, 24시간 이후에는 시스템에 의해
                            자동으로 삭제됩니다.
                        </p>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">5. 개인정보 보호를 위한 조치</h2>
                        <ul className="list-disc list-inside text-gray-700 leading-relaxed mb-6">
                            <li>개발사외 외부 접근이 차단된 상태에서 최소한의 기간(24시간) 동안 보관됩니다.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">6. 정책 변경 안내</h2>
                        <p className="text-gray-700 leading-relaxed mb-6">
                            본 개인정보 처리방침은 서비스 개선 또는 법령 변경에 따라 수정될 수 있습니다. 최신 버전은
                            키오스크 QR코드 및 현재 주소를 통해 확인할 수 있습니다.
                        </p>

                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">7. 문의</h2>
                        <p className="text-gray-700 leading-relaxed">
                            개인정보 처리방침에 대한 문의는 QR코드를 통해 접속한 웹페이지 또는 고객지원센터로 연락주시기
                            바랍니다.
                        </p>
                    </section>

                    {/* Footer */}
                    <footer className="mt-8 text-center text-black text-sm">
                        <p>본 서비스를 이용하시면 상기 개인정보 처리방침에 동의하신 것으로 간주됩니다.</p>
                    </footer>
                </div>
            </div>
        </BaseLayout>
    );
};

export default PrivacyPolicyPage;